import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  MenuItem,
  Grid,
} from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import backgroundImage from "../images/slider1.jpeg"; // Adjust path as needed

const GetQuotes = () => {
  const [selectedDate, setSelectedDate] = useState(null);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          minHeight: "100vh",
          backgroundImage: `url(${backgroundImage})`, // Use the imported image here
          backgroundSize: "cover",
          backgroundPosition: "center",
          padding: "0px 20px",
        }}
      >
        <Box
          sx={{
            maxWidth: "500px",
            width: "100%",
            padding: "20px",
            backgroundColor: "#f4ec61", // Yellow background
            borderRadius: "12px",
            boxShadow: 2,
            marginTop: "-50px", // Negative margin to reduce the gap
          }}
        >
          <Typography
            variant="h5"
            align="center"
            gutterBottom
            sx={{ color: "#333", fontWeight: "bold", marginBottom: "10px" }} // Reduce margin below heading
          >
            Get Free Quotes
          </Typography>
          <form>
            <TextField
              label="Full Name"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            />
            <TextField
              label="Phone Number"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            />
            <TextField
              label="Email Address"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            />
            <TextField
              label="Moving From"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            />
            <TextField
              label="Moving To"
              variant="outlined"
              fullWidth
              sx={{
                marginBottom: "10px",
                backgroundColor: "#fff",
                borderRadius: "8px",
              }}
            />
            {/* Combined Service Type and Date fields */}
            <Grid container spacing={2} sx={{ marginBottom: "10px" }}>
              <Grid item xs={6}>
                <TextField
                  select
                  label="Service Type"
                  variant="outlined"
                  fullWidth
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "8px",
                  }}
                >
                  {[
                    "House Shifting",
                    "Office Relocation",
                    "International Relocation",
                    "Intercity Relocation",
                    "Car Transport",
                    "Local Shifting",
                    "Domestic Relocation",
                    "Door-to-Door Delivery",
                  ].map((option) => (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={6}>
                <DatePicker
                  label="Select Date"
                  value={selectedDate}
                  onChange={(newValue) => setSelectedDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      sx={{
                        backgroundColor: "#fff",
                        borderRadius: "8px",
                      }}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              fullWidth
              sx={{
                marginTop: "10px",
                padding: "10px",
                backgroundColor: "#ef1925", // Blue button
                "&:hover": {
                  backgroundColor: "#af0c15", // Darker blue on hover
                },
              }}
            >
              Submit
            </Button>
          </form>
        </Box>
      </Box>
    </LocalizationProvider>
  );
};

export default GetQuotes;
