import React from "react";
import { Box, Grid, Typography } from "@mui/material";

// Import images
import img1 from "../images/slider1.jpeg";
import img2 from "../images/office3.jpg";
import img3 from "../images/slider3.jpg";
import img4 from "../images/slider4.jpg";
import img5 from "../images/slider5.webp";
import img6 from "../images/office1.jpg";
import img7 from "../images/office2.jpg";
import img8 from "../images/i2.avif";
import img9 from "../images/office4.jpeg";

const galleryItems = [
  {
    src: img1,
    title: "Residential Moves",
    description: "Efficient and secure residential moving services.",
  },
  {
    src: img2,
    title: "Office Relocation",
    description: "Seamless office relocation for businesses of all sizes.",
  },
  {
    src: img3,
    title: "Packing Services",
    description: "Professional packing to keep your items safe.",
  },
  {
    src: img4,
    title: "Storage Solutions",
    description: "Flexible storage options for your belongings.",
  },
  {
    src: img5,
    title: "International Moves",
    description: "Hassle-free international moving services.",
  },
  {
    src: img6,
    title: "Vehicle Transportation",
    description: "Safe and reliable vehicle transportation services.",
  },
  {
    src: img7,
    title: "Local Moves",
    description: "Quick and efficient local moving solutions.",
  },
  {
    src: img8,
    title: "Heavy Equipment Moving",
    description: "Specialized moving for heavy equipment.",
  },
  {
    src: img9,
    title: "Customized Services",
    description: "Tailored solutions to meet your unique needs.",
  },
];

const Gallery = () => {
  return (
    <Box mt={4} px={{ xs: 2, sm: 4, md: 10 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Our Gallery
      </Typography>
      <Grid container spacing={4} justifyContent="center">
        {galleryItems.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Box
              sx={{
                overflow: "hidden",
                borderRadius: 2,
                boxShadow: 3,
                transition: "transform 0.3s ease-in-out",
                "&:hover": {
                  transform: "scale(1.05)",
                },
              }}
            >
              <Box
                component="img"
                src={item.src}
                alt={item.title}
                sx={{
                  width: "100%",
                  height: "250px",
                  objectFit: "cover",
                }}
              />
            </Box>
            <Typography variant="h6" align="center" mt={2}>
              {item.title}
            </Typography>
            <Typography
              variant="body2"
              align="center"
              color="textSecondary"
              mt={1}
            >
              {item.description}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Gallery;
