import React from "react";
import { Box, Button, Typography } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp"; // Import WhatsApp icon
import { Link } from "react-router-dom"; // Import Link for navigation

const TopBar = () => {
  return (
    <Box
      sx={{
        backgroundColor: "#322",
        color: "#fff",
        padding: { xs: "8px 5px", sm: "10px 0" }, // Adjust padding for small screens
        display: "flex",
        justifyContent: "center", // Center the container horizontally
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" }, // Stack items vertically on small screens
          alignItems: "center",
          justifyContent: "space-between",
          gap: { xs: 1, sm: 3 }, // Adjust gap between items on small screens
          width: { xs: "100%", sm: "50%" }, // Full width on small screens, 50% on larger
        }}
      >
        {/* Contact Details */}
        <Typography
          component="a"
          href="mailto:info@movers.com"
          sx={{
            color: "#fff",
            textDecoration: "none",
            "&:hover": {
              transform: "scale(1.1)", // Slightly increase the size
              color: "#25D366", // Change text color to green
            },
          }}
        >
          bminfo@movers.com
        </Typography>
        <Typography
          component="a"
          href="tel:+1234567890"
          sx={{
            color: "#fff",
            textDecoration: "none",
            "&:hover": {
              transform: "scale(1.1)", // Slightly increase the size
              color: "#25D366", // Change text color to green
            },
          }}
        >
          +1234567890
        </Typography>
        <Typography
          component="a"
          href="https://wa.me/1234567890"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            color: "#fff",
            textDecoration: "none",
            "&:hover": {
              transform: "scale(1.1)", // Slightly increase the size
              color: "#25D366", // Change text color to green
            },
          }}
        >
          <WhatsAppIcon
            sx={{
              color: "#25D366", // WhatsApp green
              fontSize: "20px", // Icon size
            }}
          />
          WhatsApp
        </Typography>
        {/* Get Free Quotes Button */}
        <Link
          to="/getquotes"
          style={{ textDecoration: "none" }} // Explicitly remove underline
        >
          <Button
            variant="contained"
            sx={{
              backgroundColor: "#f4a261",
              color: "#fff",
              textTransform: "none",
              transition: "transform 0.3s ease, color 0.3s ease",
              "&:hover": {
                backgroundColor: "#e76f51",
              },
              marginTop: { xs: "10px", sm: 0 },
            }}
          >
            Get Free Quotes
          </Button>
        </Link>
      </Box>
    </Box>
  );
};

export default TopBar;
