import React, { useState } from "react";
import { Box, Grid, Typography, Button } from "@mui/material";

import slide1 from "../../images/slider1.jpg";
import slide2 from "../../images/slider2.jpg";
import slide3 from "../../images/slider3.jpg";
import slide5 from "../../images/slider5.webp";

const WhyChooseUs = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const items = [
    {
      id: 1,
      image: slide1,
      title: "One stop solution for movers and packers",
      description:
        "We have many services available with us across India and any type of services for movers and packers.",
      details:
        "With years of experience, our team ensures your goods are delivered on time without any hassle. Our process is optimized for speed and reliability.",
    },
    {
      id: 2,
      image: slide2,
      title: "Fast & Reliable and Secure Packaging",
      description:
        "We ensure timely delivery of your goods. We ensure timely delivery of your goods.",
      details:
        "With years of experience, our team ensures your goods are delivered on time without any hassle. Our process is optimized for speed and reliability.",
    },
    {
      id: 3,
      image: slide3,
      title: "Affordable Pricing",
      description: "We offer competitive rates for our services.",
      details:
        "Our pricing is transparent and competitive, ensuring you get the best value for your money without compromising on quality.",
    },
    {
      id: 4,
      image: slide5,
      title: "Secure Packaging",
      description: "Your belongings are handled with utmost care.",
      details:
        "We use high-quality packaging materials and techniques to ensure the safety of your belongings during transit.",
    },
  ];

  const handleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);

    // Ensure the element exists before trying to scroll
    const expandedElement = document.getElementById(`details-${index}`);
    if (expandedElement) {
      expandedElement.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  return (
    <Box sx={{ padding: "40px 60px", backgroundColor: "#f9f9f9" }}>
      <Typography
        variant="h4"
        sx={{
          fontWeight: "bold",
          textAlign: "center",
          marginBottom: "20px",
          color: "blue",
        }}
      >
        Why Choose Us
      </Typography>
      <Grid container spacing={3}>
        {items.map((item, index) => (
          <Grid item xs={12} sm={6} md={3} key={item.id}>
            <Box
              sx={{
                textAlign: "center",
                padding: "10px",
                backgroundColor: "#fff",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                height: "400px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
              }}
            >
              <img
                src={item.image}
                alt={item.title}
                style={{
                  width: "100%",
                  height: "200px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
              <Typography
                variant="h6"
                sx={{
                  fontWeight: "bold",
                  marginTop: "10px",
                  marginBottom: "5px",
                }}
              >
                {item.title}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  color: "gray",
                  display: "-webkit-box",
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  marginBottom: "10px",
                }}
              >
                {item.description}
              </Typography>
              <Button
                variant="contained"
                onClick={() => handleExpand(index)}
                sx={{
                  backgroundColor:
                    expandedIndex === index ? "#1976d2" : "#FF6F61", // Change color when expanded
                  "&:hover": {
                    backgroundColor:
                      expandedIndex === index ? "#1565c0" : "#FF3D30", // Darker shade on hover
                  },
                }}
              >
                {expandedIndex === index ? "Hide Details" : "More Details"}
              </Button>
            </Box>

            {/* Show expanded details below the image on mobile view */}
            {expandedIndex === index && (
              <Box
                id={`details-${index}`}
                sx={{
                  marginTop: "15px",
                  padding: "15px",
                  backgroundColor: "#fff",
                  borderRadius: "8px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
                  display: { xs: "block", sm: "none" }, // Hide on larger screens
                }}
              >
                <Typography
                  variant="h6"
                  sx={{
                    fontWeight: "bold",
                    marginBottom: "10px",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography variant="body1" sx={{ color: "black" }}>
                  {item.details}
                </Typography>
              </Box>
            )}
          </Grid>
        ))}
      </Grid>

      {/* Show details in separate section for larger screens */}
      {expandedIndex !== null && (
        <Box
          id={`details-${expandedIndex}`} // Add unique ID to each details section
          sx={{
            marginTop: "30px",
            padding: "20px",
            backgroundColor: "#fff",
            borderRadius: "10px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: "20px",
          }}
        >
          <Box sx={{ flex: "1" }}>
            <img
              src={items[expandedIndex].image}
              alt={items[expandedIndex].title}
              style={{
                width: "100%",
                height: "300px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Box>
          <Box sx={{ flex: "2" }}>
            <Typography
              variant="h6"
              sx={{ fontWeight: "bold", marginBottom: "10px" }}
            >
              {items[expandedIndex].title}
            </Typography>
            <Typography variant="body1" sx={{ color: "black" }}>
              {items[expandedIndex].details}
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default WhyChooseUs;
