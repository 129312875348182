import React, { useState } from "react";
import {
  AppBar,
  Box,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Menu,
  MenuItem,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Link } from "react-router-dom";
import logo from "../images/logo1.png";
import MenuIcon from "@mui/icons-material/Menu";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUp from "@mui/icons-material/KeyboardArrowUp";

const HeaderBar = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [drawerMenuOpen, setDrawerMenuOpen] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const toggleDrawer = (open) => {
    setDrawerOpen(open);
  };

  const toggleDrawerMenu = () => {
    setDrawerMenuOpen(!drawerMenuOpen);
  };

  const menuItems = [
    { label: "Home", to: "/" },
    { label: "Services", isDropdown: true },
    { label: "Gallary", to: "/gallary" },
    { label: "About Us", to: "/about" },
    { label: "Location", to: "/location" },
    { label: "Branches", to: "/branches" },
    { label: "Contact Us", to: "/contact-us" },
  ];

  const serviceItems = [
    { label: "House Shifting", to: "/services/houseshift" },
    { label: "Office Relocation", to: "/services/officeshift" },
    {
      label: "International Relocation",
      to: "/services/international-relocation",
    },
    { label: "Intercity Relocation", to: "/services/intercity-relocation" },
    { label: "Car Transport", to: "/services/car-transport" },
    { label: "Local Shifting", to: "/services/local-shifting" },
    { label: "Domestic Relocation", to: "/services/domestic-relocation" },
    { label: "Door-to-Door Delivery", to: "/services/door-to-door-delivery" },
  ];

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "white",
        padding: "0 10px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
        {/* Logo Section */}
        <Box sx={{ display: "flex", alignItems: "center", width: "35%" }}>
          <img
            src={logo}
            alt="Company Logo"
            style={{ height: "60px", marginRight: "20px" }}
          />
          <Typography
            variant="h5"
            sx={{ display: "flex", alignItems: "center" }}
          >
            <span
              style={{
                color: "#2C3E50",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
              }}
            >
              BM
            </span>
            <span
              style={{
                marginLeft: "10px",
                color: "#FF6F61",
                fontFamily: "Montserrat, sans-serif",
                fontWeight: "bold",
                fontStyle: "italic",
                letterSpacing: 1.5,
                textShadow: "1px 1px 2px rgba(0, 0, 0, 0.1)",
              }}
            >
              Packers & Movers
            </span>
          </Typography>
        </Box>

        {/* Desktop Navigation Links */}
        {!isSmallScreen && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: 3,
              flexGrow: 1,
            }}
          >
            {menuItems.map((item) =>
              item.isDropdown ? (
                <Box key={item.label}>
                  <Button
                    sx={{
                      color: "black",
                      textTransform: "none",
                      "&:hover": {
                        background: "rgba(0, 0, 0, 0.05)",
                        borderRadius: "8px",
                      },
                    }}
                    onClick={handleMenuOpen}
                  >
                    {item.label} ▼
                  </Button>
                  <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                    MenuListProps={{
                      "aria-labelledby": "services-button",
                    }}
                  >
                    {serviceItems.map((service) => (
                      <MenuItem
                        key={service.label}
                        component={Link}
                        to={service.to}
                        onClick={handleMenuClose}
                      >
                        {service.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Box>
              ) : (
                <Button
                  key={item.label}
                  component={Link}
                  to={item.to}
                  sx={{
                    color: "black",
                    textTransform: "none",
                    "&:hover": {
                      background: "rgba(0, 0, 0, 0.05)",
                      borderRadius: "8px",
                    },
                  }}
                >
                  {item.label}
                </Button>
              )
            )}
          </Box>
        )}

        {/* Mobile Navigation: Hamburger Icon */}
        {isSmallScreen && (
          <IconButton
            color="black"
            onClick={() => toggleDrawer(true)}
            sx={{
              border: "1px solid #FF6F61",
              background: "linear-gradient(45deg, #FFDAB9, #FF6F61)",
              borderRadius: "50%",
              "&:hover": {
                background: "linear-gradient(45deg, #FF6F61, #FFDAB9)",
              },
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
      </Toolbar>

      {/* Mobile Drawer */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        PaperProps={{
          sx: {
            backgroundColor: "#FFF8F0",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
          },
        }}
      >
        <Box sx={{ width: 250 }}>
          <List>
            {menuItems.map((item) =>
              item.isDropdown ? (
                <React.Fragment key={item.label}>
                  <ListItem button onClick={toggleDrawerMenu}>
                    <ListItemText primary={item.label} />
                    {drawerMenuOpen ? (
                      <KeyboardArrowUp />
                    ) : (
                      <KeyboardArrowDown />
                    )}
                  </ListItem>
                  {drawerMenuOpen && (
                    <List>
                      {serviceItems.map((service) => (
                        <ListItem
                          button
                          key={service.label}
                          component={Link}
                          to={service.to}
                        >
                          <ListItemText primary={service.label} />
                        </ListItem>
                      ))}
                    </List>
                  )}
                </React.Fragment>
              ) : (
                <ListItem button key={item.label} component={Link} to={item.to}>
                  <ListItemText primary={item.label} />
                </ListItem>
              )
            )}
          </List>
        </Box>
      </Drawer>
    </AppBar>
  );
};

export default HeaderBar;
