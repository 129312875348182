import React from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import img1 from "../../images/slider1.jpg";
import img2 from "../../images/slider2.jpg";

const HouseShiftingService = () => {
  return (
    <Box mt={2} px={{ xs: 2, sm: 4, md: 10 }}>
      {/* Page Title with Background */}
      <Box
        sx={{
          backgroundColor: "lightblue",
          color: "darkblue",
          width: "100%",
          padding: "16px 0",
          marginBottom: "16px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" align="center">
          House Shifting Service
        </Typography>
      </Box>

      {/* Images and Form */}
      <Grid container spacing={2} mt={2}>
        {/* Form Section row 1 */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Book for House Shifting
          </Typography>
          <GetQuotes />
        </Grid>

        {/* Wrapper Box for Details & Image Section */}
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              backgroundColor: "#f9f9f9", // Light background color
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Shadow effect
              borderRadius: "10px", // Rounded corners
              padding: "20px", // Inner padding
              display: "flex", // Flexbox to align details & images
              flexDirection: { xs: "column", md: "row" }, // Stack on small screens
              gap: "20px", // Spacing between sections
            }}
          >
            {/* Details Section row 1 */}
            <Box sx={{ flex: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ color: "red" }}>
                Best House Shifting Services Provider in Your City
              </Typography>
              <Typography variant="body1" paragraph>
                Shifting and relocation is part and parcel of the ever-evolving,
                fast-paced, and technological era. We ensure your shifting day
                as one of the happiest days where you will enjoy the new
                dwelling you will be into, because of zero hassles. So, what are
                you waiting for? Just let them know, and they will contact you
                to serve, as your satisfaction is their prime achievement!
              </Typography>

              <Typography variant="body1" paragraph>
                Moving to a new place can be such a stressful activity,
                especially if you are doing it on your own. One thing about
                relocation is that you may not be able to move all the items
                alone. Also, you might not have the expertise and experience to
                do so. Another important thing is, you may not have the right
                equipment to help you with the packing and moving process.
              </Typography>

              <Typography variant="body1">
                Since the packing and moving process can be very stressful, you
                need a reliable relocation company to help you out.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ marginTop: 2 }}
              >
                Tips for house shifting:
              </Typography>
              <ul>
                <li>Ensure everything is packed before the moving day.</li>
                <li>Make a checklist of items to pack.</li>
                <li>Wrap fragile items securely to prevent damage.</li>
                <li>Label boxes for easier unpacking.</li>
                <li>Separate essential items for quick access.</li>
              </ul>
            </Box>

            {/* Image section row 1 */}
            <Box sx={{ flex: 1 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: 2,
                      height: "250px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Box
                      component="img"
                      src={img1}
                      alt="House Shifting"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: 2,
                      height: "250px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Box
                      component="img"
                      src={img2}
                      alt="Another Image"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Important Details */}
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Important Details
        </Typography>
        <Typography variant="body1" color="textSecondary">
          - Ensure all items are packed securely.
          <br />
          - Provide accurate details for a hassle-free experience.
          <br />
          - Contact us for any specific requirements.
          <br />- All charges include taxes unless stated otherwise.
        </Typography>
      </Box>

      {/* Charges Table */}
      <Box mt={6}>
        <Typography variant="h5" gutterBottom>
          Charges for House Shifting
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Goods</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 10km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 20km</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 80km</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>1BHK</TableCell>
                <TableCell align="center">₹2000</TableCell>
                <TableCell align="center">₹3000</TableCell>
                <TableCell align="center">₹5000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>2BHK</TableCell>
                <TableCell align="center">₹3000</TableCell>
                <TableCell align="center">₹4000</TableCell>
                <TableCell align="center">₹6000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>3BHK</TableCell>
                <TableCell align="center">₹4000</TableCell>
                <TableCell align="center">₹5000</TableCell>
                <TableCell align="center">₹8000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default HouseShiftingService;

// Local GetQuotes Component
const GetQuotes = () => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 3,
        boxShadow: 2,
        borderRadius: 2,
        backgroundColor: "yellow", // Yellow background for the form
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center the form contents
        gap: 2, // Uniform gap between fields
        maxWidth: 400, // Optional: Limit the form width
        margin: "0 auto", // Center the form horizontally
      }}
    >
      <TextField
        label="Full Name"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Contact Number"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Moving From"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Moving To"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        select
        label="Service Type"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for dropdown
          },
        }}
        SelectProps={{
          native: true,
        }}
      >
        <option value=""> </option>
        <option value="house-shifting">House Shifting</option>
        <option value="office-relocation">Office Relocation</option>
        <option value="international-relocation">
          International Relocation
        </option>
        <option value="intercity-relocation">Intercity Relocation</option>
        <option value="car-transport">Car Transport</option>
        <option value="local-shifting">Local Shifting</option>
        <option value="domestic-relocation">Domestic Relocation</option>
        <option value="door-to-door-delivery">Door-to-Door Delivery</option>
      </TextField>
      <TextField
        label="Preferred Date"
        type="date"
        variant="outlined"
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{
          textTransform: "none",
          padding: "10px",
          fontSize: "16px",
          backgroundColor: "#ff0066", // Red button color
          "&:hover": {
            backgroundColor: "darkred", // Darker red on hover
          },
        }}
      >
        Submit
      </Button>
    </Box>
  );
};
