import React from "react";
import {
  Box,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import img1 from "../../images/i2.avif";
import img2 from "../../images/i3.jpeg";

const IntercityRelocationService = () => {
  return (
    <Box mt={2} px={{ xs: 2, sm: 4, md: 10 }}>
      {/* Page Title with Background */}
      <Box
        sx={{
          backgroundColor: "lightblue",
          color: "darkblue",
          width: "100%",
          padding: "16px 0",
          marginBottom: "16px",
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Typography variant="h5" align="center">
          Intercity Relocation Service
        </Typography>
      </Box>

      {/* Images and Form */}
      <Grid container spacing={2} mt={2}>
        {/* Form Section row 1 */}
        <Grid item xs={12} md={3}>
          <Typography variant="h6" gutterBottom>
            Book for Intercity Relocation
          </Typography>
          <GetQuotes />
        </Grid>

        {/* Wrapper Box for Details & Image Section */}
        <Grid item xs={12} md={9}>
          <Box
            sx={{
              backgroundColor: "#f9f9f9", // Light background color
              boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)", // Shadow effect
              borderRadius: "10px", // Rounded corners
              padding: "20px", // Inner padding
              display: "flex", // Flexbox to align details & images
              flexDirection: { xs: "column", md: "row" }, // Stack on small screens
              gap: "20px", // Spacing between sections
            }}
          >
            {/* Details Section row 1 */}
            <Box sx={{ flex: 2 }}>
              <Typography variant="h5" gutterBottom sx={{ color: "red" }}>
                Best Intercity Relocation Services Provider
              </Typography>
              <Typography variant="body1" paragraph>
                Moving within a city or between cities can be challenging, but
                with our professional intercity relocation services, we ensure a
                smooth and efficient move. Whether you're relocating for work,
                study, or personal reasons, we handle all the details for you.
              </Typography>

              <Typography variant="body1" paragraph>
                Our team understands the intricacies of intercity moves, from
                packing and loading to transportation and unloading. We ensure
                your belongings reach their destination safely and on time.
              </Typography>

              <Typography variant="body1">
                With years of experience and a dedicated team, we guarantee that
                your intercity move is completed with minimal stress.
              </Typography>
              <Typography
                variant="body1"
                color="textSecondary"
                sx={{ marginTop: 2 }}
              >
                Tips for intercity relocation:
              </Typography>
              <ul>
                <li>Plan ahead and make a list of your items.</li>
                <li>Pack items securely to avoid damage during transit.</li>
                <li>Ensure that all necessary documentation is in order.</li>
                <li>Consider hiring professionals to handle heavy items.</li>
              </ul>
            </Box>

            {/* Image section row 1 */}
            <Box sx={{ flex: 1 }}>
              <Grid container direction="column" spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: 2,
                      height: "250px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Box
                      component="img"
                      src={img1}
                      alt="Intercity Relocation"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      overflow: "hidden",
                      borderRadius: 2,
                      height: "250px",
                      transition: "transform 0.3s ease-in-out",
                      "&:hover": { transform: "scale(1.05)" },
                    }}
                  >
                    <Box
                      component="img"
                      src={img2}
                      alt="Another Image"
                      sx={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Box>
        </Grid>
      </Grid>

      {/* Important Details */}
      <Box mt={3}>
        <Typography variant="h6" gutterBottom>
          Important Details
        </Typography>
        <Typography variant="body1" color="textSecondary">
          - Ensure all items are packed securely for intercity transport.
          <br />
          - Provide accurate details for a smooth relocation process.
          <br />
          - Contact us for any specific requirements or assistance.
          <br />- All charges include taxes unless stated otherwise.
        </Typography>
      </Box>

      {/* Charges Table */}
      <Box mt={6}>
        <Typography variant="h5" gutterBottom>
          Charges for Intercity Relocation
        </Typography>
        <TableContainer component={Paper} sx={{ mt: 2 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>
                  <strong>Goods</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 10kg</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 50kg</strong>
                </TableCell>
                <TableCell align="center">
                  <strong>Up to 100kg</strong>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Personal Belongings</TableCell>
                <TableCell align="center">₹5,000</TableCell>
                <TableCell align="center">₹10,000</TableCell>
                <TableCell align="center">₹15,000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Household Items</TableCell>
                <TableCell align="center">₹7,500</TableCell>
                <TableCell align="center">₹12,000</TableCell>
                <TableCell align="center">₹18,000</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Office Relocation</TableCell>
                <TableCell align="center">₹10,000</TableCell>
                <TableCell align="center">₹15,000</TableCell>
                <TableCell align="center">₹25,000</TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default IntercityRelocationService;

// Local GetQuotes Component
const GetQuotes = () => {
  return (
    <Box
      component="form"
      noValidate
      autoComplete="off"
      sx={{
        padding: 3,
        boxShadow: 2,
        borderRadius: 2,
        backgroundColor: "yellow", // Yellow background for the form
        display: "flex",
        flexDirection: "column",
        alignItems: "center", // Center the form contents
        gap: 2, // Uniform gap between fields
        maxWidth: 400, // Optional: Limit the form width
        margin: "0 auto", // Center the form horizontally
      }}
    >
      <TextField
        label="Full Name"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Contact Number"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Email"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Moving From"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        label="Moving To"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <TextField
        select
        label="Service Type"
        variant="outlined"
        fullWidth
        size="small"
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for dropdown
          },
        }}
        SelectProps={{
          native: true,
        }}
      >
        <option value=""> </option>
        <option value="house-shifting">House Shifting</option>
        <option value="office-relocation">Office Relocation</option>
        <option value="international-relocation">
          International Relocation
        </option>
        <option value="intercity-relocation">Intercity Relocation</option>
        <option value="car-transport">Car Transport</option>
        <option value="local-shifting">Local Shifting</option>
        <option value="domestic-relocation">Domestic Relocation</option>
        <option value="door-to-door-delivery">Door-to-Door Delivery</option>
      </TextField>
      <TextField
        label="Preferred Date"
        type="date"
        variant="outlined"
        fullWidth
        size="small"
        InputLabelProps={{ shrink: true }}
        sx={{
          "& .MuiInputBase-root": {
            backgroundColor: "white", // White background for input
          },
        }}
      />
      <Button
        variant="contained"
        fullWidth
        sx={{
          textTransform: "none",
          padding: "10px",
          fontSize: "16px",
          backgroundColor: "#ff0066", // Red button color
          "&:hover": {
            backgroundColor: "#cc0052", // Darker red on hover
          },
        }}
      >
        Get Free Quote
      </Button>
    </Box>
  );
};
