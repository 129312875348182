import React, { useEffect } from "react";
import Slider from "react-slick";
import { Box, Typography } from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Import images
import slide1 from "../images/about/about1.jpg";
import slide3 from "../images/about/about3.png";
import slide4 from "../images/about/about4.jpg";

const SliderArrow = ({ className, style, onClick, direction }) => (
  <Box
    className={className}
    sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      position: "absolute",
      top: "50%",
      zIndex: 2,
      cursor: "pointer",
      [direction === "left" ? "left" : "right"]: "5%",
      transform: "translateY(-50%)",
      background: "rgba(0, 0, 0, 0.5)",
      borderRadius: "50%",
      width: "50px",
      height: "50px",
      color: "#fff",
      "&:hover": {
        background: "rgba(0, 0, 0, 0.7)",
      },
    }}
    onClick={onClick}
    style={{ ...style }}
  >
    {direction === "left" ? "<" : ">"}
  </Box>
);

const SliderAbout = () => {
  useEffect(() => {
    // Use JavaScript to hide the dots after the component is mounted
    const dots = document.querySelectorAll(".slick-dots");
    dots.forEach((dot) => {
      dot.style.display = "none";
    });
  }, []);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SliderArrow direction="right" />,
    prevArrow: <SliderArrow direction="left" />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: false,
        },
      },
    ],
  };

  const slides = [
    {
      id: 1,
      image: slide4,
      title: "24/7 Support",
      description: "Experience hassle-free relocation with our expert team.",
    },
    {
      id: 2,
      image: slide1,
      title: "Premium Packaging",
      description: "Safe with us and Fast delivery",
    },
    {
      id: 3,
      image: slide3,
      title: "Registered company",
      description: "All over India service.",
    },
  ];

  return (
    <Box sx={{ maxWidth: "100%", overflow: "hidden", position: "relative" }}>
      {/* Add style tag directly in JSX to hide the dots */}
      <style>
        {`
          .slick-dots {
            display: none !important;
          }
        `}
      </style>
      <Slider {...settings}>
        {slides.map((slide) => (
          <Box
            key={slide.id}
            sx={{
              position: "relative",
              height: { xs: "120px", sm: "200px", md: "250px" }, // Reduced heights
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundImage: `url(${slide.image})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              color: "#fff",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                bottom: "15%",
                textAlign: "center",
                background: "rgba(0, 0, 0, 0.5)",
                padding: "10px 20px", // Adjust padding to match reduced height
                borderRadius: "8px",
              }}
            >
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {slide.title}
              </Typography>
              <Typography variant="body2">{slide.description}</Typography>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default SliderAbout;
