import React from "react";
import SliderAbout from "../components/SliderAbout";
import { Box, Grid, Typography } from "@mui/material";
import aboutImage from "../images/about/about1.jpg"; // Replace with the actual path to your image
import aboutImage2 from "../images/about/about3.png"; // Replace with the actual path to your image
import aboutImage3 from "../images/about/about4.jpg";

const AboutUs = () => {
  return (
    <div>
      <SliderAbout />
      <Box sx={{ padding: "60px" }}>
        <Grid container spacing={2}>
          {/* Image Section */}
          <Grid item xs={12} md={4}>
            <Grid item xs={12} md={12}>
              <Box
                component="img"
                src={aboutImage}
                alt="About Us"
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "600px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Box
                component="img"
                src={aboutImage3}
                alt="About Us"
                sx={{
                  width: "100%",
                  height: "auto",
                  maxHeight: "500px",
                  objectFit: "cover",
                  borderRadius: "8px",
                }}
              />
            </Grid>
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} md={8}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              Welcome to Movers & Packers
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                lineHeight: 1.8,
                textAlign: "justify",
              }}
            >
              BM Express Packers And Movers is a well-known name in the Packers
              and Movers sector in India. BM Packers and Movers was established
              in 2013. We offer reliable domestic and international Packers and
              Movers services in India. Our team of professionals handles all
              aspects of international moving. The BM Express holds the
              expertise in working with supreme professionalism throughout the
              process of service offering. It facilitates smooth and timely
              delivery of goods at affordable rates.
              <br />
              <br />
              We provide door-to-door delivery along with packaging, loading,
              unloading, and transportation services in Pune, Mumbai, Bangalore,
              Hyderabad, Delhi, and other metropolitan cities of India. BM
              Express Packers And Movers is an MSME-recognized & ISO-certified
              12+ year-old packing and moving company in India. We are a
              government-licensed and authorized Packers and Movers based in
              Pune. BM Express Packers and Movers is amongst the leading service
              providers of packaging and moving in India. We offer fast,
              reliable, and professional Packers and Movers services in India.
              With our experience, expertise, and market understanding, we have
              a network across major cities in India. BM Express Movers and
              Packers comprises professionals and skilled labor trained to
              handle all belongings, commercial or personal, with utmost care.
            </Typography>
          </Grid>
        </Grid>
      </Box>

      {/*our vission/ mission*/}
      <Box sx={{ padding: "60px" }}>
        <Grid container spacing={2}>
          {/* Image Section */}
          <Grid item xs={12} md={5}>
            <Box
              component="img"
              src={aboutImage2}
              alt="About Us"
              sx={{
                width: "100%",
                height: "auto",
                maxHeight: "500px",
                objectFit: "cover",
                borderRadius: "8px",
              }}
            />
          </Grid>

          {/* Text Section */}
          <Grid item xs={12} md={7}>
            <Typography
              variant="h5"
              gutterBottom
              sx={{ fontWeight: "bold", color: "#333" }}
            >
              Our Vision & mission
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#555",
                lineHeight: 1.6,
                textAlign: "justify",
              }}
            >
              BM Express Packers And Movers Vision is to To become the most
              trusted and preferred moving and packing partner by delivering
              seamless relocation experiences with exceptional service quality
              and care..
              <br />
              <br />
              To revolutionize the relocation industry through innovative,
              technology-driven solutions, ensuring efficiency, safety, and
              customer satisfaction at every step.
              <br />
              <br />
              To lead the moving and packing industry with sustainable practices
              that minimize environmental impact while delivering top-notch
              services to our customer
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default AboutUs;
