import React from "react";

const Services = () => {
  return (
    <div>
      <h1>Our Services</h1>
      <p>
        We provide a wide range of services for packers and movers, including
        residential and commercial relocation.
      </p>
    </div>
  );
};

export default Services;
