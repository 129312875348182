import React from "react";
import Slider from "../../components/Slider";
import { Box, Grid, Typography, TextField, Button } from "@mui/material";
import WhyChooseUs from "./WhyChooseUs";

const Home = () => {
  return (
    <div>
      {/* Slider Section */}
      <Slider />

      {/* Row Below Slider */}
      <Box sx={{ padding: "20px 80px 20px 100px", backgroundColor: "#f9f9f9" }}>
        <Grid container spacing={2}>
          {/* Left Section: Form */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                backgroundColor: "yellow",
                padding: "20px",
                borderRadius: "8px",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h5"
                sx={{
                  color: "blue",
                  fontWeight: "bold",
                  marginBottom: "20px",
                  textAlign: "center",
                }}
              >
                Get Free Quotes
              </Typography>
              <form>
                <TextField
                  fullWidth
                  label="Name"
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Email"
                  type="email"
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Mobile No"
                  type="tel"
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <TextField
                  fullWidth
                  label="From (Location)"
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <TextField
                  fullWidth
                  label="To (Location)"
                  variant="outlined"
                  sx={{
                    marginBottom: "15px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <TextField
                  fullWidth
                  label="Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  sx={{
                    marginBottom: "20px",
                    backgroundColor: "white",
                    input: { color: "black" },
                  }}
                />
                <Button
                  variant="contained"
                  fullWidth
                  sx={{
                    backgroundColor: "red",
                    color: "white",
                    fontWeight: "bold",
                    "&:hover": { backgroundColor: "darkred" },
                  }}
                >
                  Submit
                </Button>
              </form>
            </Box>
          </Grid>

          {/* Right Section: Welcome Message */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                textAlign: "center",
                padding: "20px",
                borderRadius: "8px",
                backgroundColor: "#fff",
                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  fontWeight: "bold",
                  color: "blue",
                  marginBottom: "15px",
                }}
              >
                Welcome to BM Packers and Movers in India
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: "black", lineHeight: 1.6 }}
              >
                {[
                  "BM Express Packers And Movers is a well-known name in the Packers and Movers sector in India. BM Packers and Movers was established in 2015. We offer reliable Domestic & International Packers Movers services in India. Our team of professionals handles all aspects of international moving.",
                  "BM Express holds the expertise in working with supreme professionalism throughout the process of service offering. It facilitates smooth and timely delivery of goods at affordable rates. We provide door-to-door delivery along with packaging, loading, unloading, and transportation services in Pune, Mumbai, Bangalore, Hyderabad, Delhi, and other metropolitan cities of India.",
                  "BM Express Packers And Movers is an MSME recognized & ISO Certified 12+ year-old packing and moving company in India. We are a government licensed and authorized packers and movers based in Pune. BM Express Packers and Movers is amongst the leading service providers of packaging and moving in India.",
                  "We offer fast, reliable, and professional packers and movers services in India. With our experience, expertise, and market understanding, we have a network across major cities of India. BM Express Movers and Packers comprises professionals as well as skilled labor trained to handle all belongings, commercial or personal, with utmost care.",
                ].map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split(/(BM)/).map((segment, idx) =>
                      segment === "BM" ? (
                        <b
                          key={idx}
                          style={{ fontWeight: "bold", color: "#2C3E50" }}
                        >
                          {segment}
                        </b>
                      ) : (
                        segment
                      )
                    )}
                    {index === 1 && (
                      <>
                        <br />
                        <br />
                        We are committed to delivering a hassle-free relocation
                        experience for individuals and businesses alike, making
                        moving in India stress-free and convenient.
                      </>
                    )}
                  </p>
                ))}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Why Choose Us Section */}
      <WhyChooseUs />
    </div>
  );
};

export default Home;
