import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./pages/Homepage/Home";
import AboutUs from "./pages/AboutUs";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import GetQuotes from "./pages/GetQuotes"; // Import the new component
import HouseShiftingService from "./pages/services/HouseShiftingService";
import OfficeRelocationService from "./pages/services/OfficeRelocationService";
import InternationalRelocationService from "./pages/services/InternationalRelocationService";
import IntercityRelocationService from "./pages/services/IntercityRelocationService";

function App() {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/services" element={<Services />} />
          <Route path="/gallary" element={<Gallery />} />
          <Route path="/getquotes" element={<GetQuotes />} /> {/* New Route */}
          {/* Service-specific routes */}
          <Route
            path="/services/houseshift"
            element={<HouseShiftingService />}
          />
          <Route
            path="/services/officeshift"
            element={<OfficeRelocationService />}
          />
          <Route
            path="/services/international-relocation"
            element={<InternationalRelocationService />}
          />
          <Route
            path="/services/intercity-relocation"
            element={<IntercityRelocationService />}
          />
        </Routes>
      </Layout>
    </Router>
  );
}

export default App;
