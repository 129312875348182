import React from "react";
import TopBar from "../pages/TopBar";
import HeaderBar from "../pages/HeaderBar";
import { IconButton } from "@mui/material";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import CallIcon from "@mui/icons-material/Call";

const Layout = ({ children }) => {
  return (
    <div>
      {/* Add TopBar and HeaderBar */}
      <TopBar />
      <HeaderBar />

      {/* Fixed position for WhatsApp and Phone Call icons */}
      <div
        style={{
          position: "fixed",
          left: "10px", // Distance from the left edge of the screen
          top: "65%", // Position in the center of the screen
          transform: "translateY(-50%)", // To center vertically
          zIndex: 1000, // Ensure the icons are on top of other content
          display: "flex", // Use flexbox to arrange icons vertically
          flexDirection: "column", // Stack icons vertically
          alignItems: "center", // Center the icons horizontally
          gap: "15px", // Add space between icons
        }}
      >
        {/* WhatsApp Icon */}
        <IconButton
          href="https://wa.me/your-whatsapp-number" // Add your WhatsApp number here
          target="_blank"
          sx={{
            backgroundColor: "#25D366",
            color: "white",
            "&:hover": {
              backgroundColor: "#128C7E",
            },
          }}
        >
          <WhatsAppIcon />
        </IconButton>

        {/* Phone Call Icon */}
        <IconButton
          href="tel:+your-phone-number" // Add your phone number here
          sx={{
            backgroundColor: "#4CAF50",
            color: "white",
            "&:hover": {
              backgroundColor: "#388E3C",
            },
          }}
        >
          <CallIcon />
        </IconButton>
      </div>

      {/* Main content */}
      <main>{children}</main>
    </div>
  );
};

export default Layout;
